<template>
    <div id="products-page" role="main">
        <section class="hero">
            <div class="hero-body">
                <div class="container has-text-centered">
                    <h1 class="title xl mb-0 pb-2">Platforms</h1>
                    <!-- <div class="div">
                        <h3 class="title is-3">Text here</h3>
                    </div> -->
                </div>
            </div>
        </section>

        <section class="section">
            <div class="container">
                <div class="columns is-multiline is-centered">
                    <div v-for="product in products"
                         :key="product.id"
                         @click="goToProduct(product.url)"
                         class="product column is-5 mx-3 is-flex is-flex-direction-column is-flex-wrap-nowrap is-align-items-left ">
                        <div class="product-logo-wrapper">
                            <img :src="productLogoImage(product.logo)"
                                 :alt="product.logo.alternativeText"
                                 class="product-logo" />
                        </div>
                        <p class="product-tagline title"
                           :class="{ 'is-draft': product.published_at === null }">
                            {{ product.tagline }}
                        </p>
                        <p class="product-description">
                            {{ product.description }}
                        </p>
                        <a :href="getProductUrl(product.url)"
                           :target="getProductTarget(product.url)"
                           class="primary-button product-more-button button is-radiusless has-text-weight-bold is-align-self-baseline ">
                            <span v-if="product.url">Learn More</span>
                            <span v-else>Contact Us</span>
                            <i class=" fas fa-arrow-right ml-2 primary-button-icon "></i>
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <hr/>

        <section class="section is-small has-text-centered mt-0">
            <div class="title is-4">Want to know more?</div>
            <router-link :to="{ name: 'Contact' }">
                <button class="
                        button
                        is-primary is-radiusless
                        has-text-weight-bold
                        primary-button
                    ">
                    Contact Us
                    <i class="fas fa-arrow-right ml-2 primary-button-icon"></i>
                </button>
            </router-link>
        </section>

    </div>
</template>

<script>
export default {
    name: 'Platforms',
    data: () => {
        return {
            name: 'Platforms'
        };
    },
    metaInfo: {
        title: 'Platforms',
        template: null
    },
    mounted() {
        if (this.$store.state.products.length < 1)
            this.$store.dispatch("getProducts");
    },
    computed: {
        products() {
            var products = this.$store.state.products;
            return products.filter((x) => x.showOnHomePage === true);
        },
    },
    methods: {
        getProductUrl(url) {
            if (url) return url;
            return "/contact";
        },
        getProductTarget(url) {
            // console.log("url:", url);
            if (url.charAt(0) === "/") return "";
            if (url) return "_blank";
            return "";
        },
        goToProduct(url) {
            if (url.charAt(0) === "/") {
                this.$router.push(url);
            } else if (url) {
                window.open(url, "_blank").focus();
            } else {
                this.$router.push("/contact");
            }
        },
        productLogoImage(logo) {
            var apiUrl = this.$store.state.strapiApiUrl;

            if (logo) {
                // // if (logo.formats && logo.formats.thumbnail) {
                // //     return apiUrl + logo.formats.thumbnail.url
                // // }
                // if (logo.formats && logo.formats.small) {
                //     return apiUrl + logo.formats.small.url
                // }
                if (logo.formats && logo.formats.medium) {
                    return apiUrl + logo.formats.medium.url;
                }
                if (logo.formats && logo.formats.large) {
                    return apiUrl + logo.formats.large.url;
                }
                return apiUrl + logo.url;
            }
            return null;
        }
    }
};
</script>

<style scoped></style>
